import * as i0 from '@angular/core';
import { Directive, NgModule } from '@angular/core';

/**
 * Blank directive for queries via `@ContentChildren` / `@ViewChildren` / `querySelector`
 */
class TuiItemDirective {}
TuiItemDirective.ɵfac = function TuiItemDirective_Factory(t) {
  return new (t || TuiItemDirective)();
};
TuiItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiItemDirective,
  selectors: [["", "tuiItem", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiItem]'
    }]
  }], null, null);
})();
class TuiItemModule {}
TuiItemModule.ɵfac = function TuiItemModule_Factory(t) {
  return new (t || TuiItemModule)();
};
TuiItemModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiItemModule
});
TuiItemModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiItemDirective],
      exports: [TuiItemDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiItemDirective, TuiItemModule };
