import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Optional, SkipSelf, forwardRef, Directive, Inject, Input, ElementRef, INJECTOR, HostListener, Component, ChangeDetectionStrategy, Self, HostBinding, NgZone, TemplateRef, NgModule } from '@angular/core';
import * as i3 from '@taiga-ui/cdk';
import { tuiCreateTokenFromFactory, tuiCreateToken, AbstractTuiController, TuiActiveZoneDirective, TuiHoveredService, EMPTY_CLIENT_RECT, tuiPointToClientRect, tuiPure, tuiClamp, tuiPx, TuiDestroyService, tuiIfMap, tuiTypedFromEvent, tuiIsPresent, tuiZoneOptimized, tuiIsNativeFocused, TUI_PARENT_ANIMATION } from '@taiga-ui/cdk';
import * as i1$1 from '@taiga-ui/core/abstract';
import { tuiAsRectAccessor, tuiAsVehicle, TuiDriver, tuiAsDriver, TuiPositionAccessor, tuiFallbackRectAccessor, TuiRectAccessor, tuiPositionAccessorFor, tuiRectAccessorFor, AbstractTuiDriverDirective, TuiVehicle } from '@taiga-ui/core/abstract';
import { tuiFadeIn } from '@taiga-ui/core/animations';
import * as i5 from '@taiga-ui/core/directives/mode';
import { TuiModeDirective } from '@taiga-ui/core/directives/mode';
import * as i2 from '@taiga-ui/core/services';
import { TuiHintService, TuiPositionService, TuiVisualViewportService } from '@taiga-ui/core/services';
import { TUI_VIEWPORT, TUI_ANIMATION_OPTIONS } from '@taiga-ui/core/tokens';
import { tuiOverrideOptions, tuiIsObscured } from '@taiga-ui/core/utils';
import * as i1 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusComponent, POLYMORPHEUS_CONTEXT, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { switchMap, delay, takeUntil, repeat, filter, map, tap, debounce, startWith, distinctUntilChanged, skip } from 'rxjs/operators';
import * as i2$1 from 'rxjs';
import { Subject, merge, of, ReplaySubject, timer, BehaviorSubject } from 'rxjs';
import { TUI_HINT_DIRECTIONS } from '@taiga-ui/core/constants';
import { DOCUMENT, CommonModule } from '@angular/common';

/**
 * A component to display a hint
 */
const _c0 = ["*"];
function TuiHintComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("innerHTML", text_r1, i0.ɵɵsanitizeHtml);
  }
}
function TuiHintUnstyledComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const TUI_HINT_COMPONENT = tuiCreateTokenFromFactory(() => TuiHintComponent);

/** Default values for hint options */
const TUI_HINT_DEFAULT_OPTIONS = {
  direction: 'bottom-left',
  showDelay: 500,
  hideDelay: 200,
  appearance: '',
  icon: 'tuiIconHelpCircle'
};
/**
 * Default parameters for hint directive
 */
const TUI_HINT_OPTIONS = tuiCreateToken(TUI_HINT_DEFAULT_OPTIONS);
const tuiHintOptionsProvider = override => ({
  provide: TUI_HINT_OPTIONS,
  deps: [[new Optional(), TuiHintOptionsDirective], [new Optional(), new SkipSelf(), TUI_HINT_OPTIONS]],
  useFactory: tuiOverrideOptions(override, TUI_HINT_DEFAULT_OPTIONS)
});
class TuiHintOptionsDirective extends AbstractTuiController {
  constructor(options) {
    super();
    this.options = options;
    this.direction = this.options.direction;
    this.appearance = this.options.appearance;
    this.showDelay = this.options.showDelay;
    this.hideDelay = this.options.hideDelay;
    this.icon = this.options.icon;
  }
}
TuiHintOptionsDirective.ɵfac = function TuiHintOptionsDirective_Factory(t) {
  return new (t || TuiHintOptionsDirective)(i0.ɵɵdirectiveInject(TUI_HINT_OPTIONS, 4));
};
TuiHintOptionsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintOptionsDirective,
  selectors: [["", "tuiHintContent", ""]],
  inputs: {
    content: [i0.ɵɵInputFlags.None, "tuiHintContent", "content"],
    direction: [i0.ɵɵInputFlags.None, "tuiHintDirection", "direction"],
    appearance: [i0.ɵɵInputFlags.None, "tuiHintAppearance", "appearance"],
    showDelay: [i0.ɵɵInputFlags.None, "tuiHintShowDelay", "showDelay"],
    hideDelay: [i0.ɵɵInputFlags.None, "tuiHintHideDelay", "hideDelay"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_HINT_OPTIONS,
    useExisting: forwardRef(() => TuiHintOptionsDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintOptionsDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHintContent]',
      providers: [{
        provide: TUI_HINT_OPTIONS,
        useExisting: forwardRef(() => TuiHintOptionsDirective)
      }]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: SkipSelf
      }, {
        type: Inject,
        args: [TUI_HINT_OPTIONS]
      }]
    }];
  }, {
    content: [{
      type: Input,
      args: ['tuiHintContent']
    }],
    direction: [{
      type: Input,
      args: ['tuiHintDirection']
    }],
    appearance: [{
      type: Input,
      args: ['tuiHintAppearance']
    }],
    showDelay: [{
      type: Input,
      args: ['tuiHintShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['tuiHintHideDelay']
    }]
  });
})();
class TuiHintDirective {
  constructor(el, component, hintService, options, activeZone) {
    this.el = el;
    this.component = component;
    this.hintService = hintService;
    this.options = options;
    this.activeZone = activeZone;
    this.tuiHintAppearance = null;
    this.type = 'hint';
  }
  set tuiHint(content) {
    this.content = content;
    if (!content) {
      this.toggle(false);
    }
  }
  get appearance() {
    var _a;
    return (_a = this.tuiHintAppearance) !== null && _a !== void 0 ? _a : this.options.appearance;
  }
  ngOnDestroy() {
    this.toggle(false);
  }
  getClientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }
  toggle(show) {
    if (show && this.content) {
      this.hintService.add(this);
    } else {
      this.hintService.remove(this);
    }
  }
}
TuiHintDirective.ɵfac = function TuiHintDirective_Factory(t) {
  return new (t || TuiHintDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(PolymorpheusComponent), i0.ɵɵdirectiveInject(TuiHintService), i0.ɵɵdirectiveInject(TUI_HINT_OPTIONS), i0.ɵɵdirectiveInject(TuiActiveZoneDirective, 8));
};
TuiHintDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintDirective,
  selectors: [["", "tuiHint", "", 5, "ng-container", 5, "ng-template"]],
  inputs: {
    tuiHint: "tuiHint",
    context: [i0.ɵɵInputFlags.None, "tuiHintContext", "context"],
    tuiHintAppearance: "tuiHintAppearance"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintDirective), tuiAsVehicle(TuiHintDirective), {
    provide: PolymorpheusComponent,
    deps: [TUI_HINT_COMPONENT, INJECTOR],
    useClass: PolymorpheusComponent
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint]:not(ng-container):not(ng-template)',
      providers: [tuiAsRectAccessor(TuiHintDirective), tuiAsVehicle(TuiHintDirective), {
        provide: PolymorpheusComponent,
        deps: [TUI_HINT_COMPONENT, INJECTOR],
        useClass: PolymorpheusComponent
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.PolymorpheusComponent,
      decorators: [{
        type: Inject,
        args: [PolymorpheusComponent]
      }]
    }, {
      type: i2.TuiHintService,
      decorators: [{
        type: Inject,
        args: [TuiHintService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_HINT_OPTIONS]
      }]
    }, {
      type: i3.TuiActiveZoneDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiActiveZoneDirective]
      }]
    }];
  }, {
    tuiHint: [{
      type: Input
    }],
    context: [{
      type: Input,
      args: ['tuiHintContext']
    }],
    tuiHintAppearance: [{
      type: Input
    }]
  });
})();

/* eslint-disable rxjs/no-unsafe-takeuntil */
class TuiHintHoverDirective extends TuiDriver {
  constructor(hovered$, options, el, parent) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.hovered$ = hovered$;
    this.options = options;
    this.el = el;
    this.parent = parent;
    this.visible = false;
    this.toggle$ = new Subject();
    this.stream$ = merge(this.toggle$.pipe(switchMap(visible => of(visible).pipe(delay(visible ? 0 : this.hideDelay))), takeUntil(this.hovered$), repeat()), this.hovered$.pipe(switchMap(visible => of(visible).pipe(delay(visible ? this.showDelay : this.hideDelay))), takeUntil(this.toggle$), repeat())).pipe(filter(() => this.enabled), map(value => value && (this.el.nativeElement.hasAttribute('tuiHintPointer') || !tuiIsObscured(this.el.nativeElement))), tap(visible => {
      this.visible = visible;
    }));
    this.showDelay = this.options.showDelay;
    this.hideDelay = this.options.hideDelay;
    this.type = 'hint';
    this.enabled = true;
  }
  toggle(visible = !this.visible) {
    var _a;
    this.toggle$.next(visible);
    (_a = this.parent) === null || _a === void 0 ? void 0 : _a.toggle(visible);
  }
}
TuiHintHoverDirective.ɵfac = function TuiHintHoverDirective_Factory(t) {
  return new (t || TuiHintHoverDirective)(i0.ɵɵdirectiveInject(TuiHoveredService), i0.ɵɵdirectiveInject(TUI_HINT_OPTIONS), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiHintHoverDirective, 12));
};
TuiHintHoverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintHoverDirective,
  selectors: [["", "tuiHint", "", 5, "ng-container", 5, "ng-template"]],
  inputs: {
    showDelay: [i0.ɵɵInputFlags.None, "tuiHintShowDelay", "showDelay"],
    hideDelay: [i0.ɵɵInputFlags.None, "tuiHintHideDelay", "hideDelay"]
  },
  exportAs: ["tuiHintHover"],
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintHoverDirective), TuiHoveredService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintHoverDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint]:not(ng-container):not(ng-template)',
      providers: [tuiAsDriver(TuiHintHoverDirective), TuiHoveredService],
      exportAs: 'tuiHintHover'
    }]
  }], function () {
    return [{
      type: i2$1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiHoveredService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_HINT_OPTIONS]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: TuiHintHoverDirective,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }, {
        type: Inject,
        args: [TuiHintHoverDirective]
      }]
    }];
  }, {
    showDelay: [{
      type: Input,
      args: ['tuiHintShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['tuiHintHideDelay']
    }]
  });
})();
class TuiHintPointerDirective extends TuiHintHoverDirective {
  constructor() {
    super(...arguments);
    this.currentRect = EMPTY_CLIENT_RECT;
  }
  onMove({
    clientX,
    clientY
  }) {
    this.currentRect = tuiPointToClientRect(clientX, clientY);
  }
  getClientRect() {
    return this.currentRect;
  }
}
TuiHintPointerDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiHintPointerDirective_BaseFactory;
  return function TuiHintPointerDirective_Factory(t) {
    return (ɵTuiHintPointerDirective_BaseFactory || (ɵTuiHintPointerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintPointerDirective)))(t || TuiHintPointerDirective);
  };
})();
TuiHintPointerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintPointerDirective,
  selectors: [["", "tuiHint", "", "tuiHintPointer", ""]],
  hostBindings: function TuiHintPointerDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("mousemove.silent", function TuiHintPointerDirective_mousemove_silent_HostBindingHandler($event) {
        return ctx.onMove($event);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintPointerDirective), tuiAsDriver(TuiHintPointerDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintPointerDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint][tuiHintPointer]',
      providers: [tuiAsRectAccessor(TuiHintPointerDirective), tuiAsDriver(TuiHintPointerDirective)]
    }]
  }], null, {
    onMove: [{
      type: HostListener,
      args: ['mousemove.silent', ['$event']]
    }]
  });
})();
const OFFSET = 8;
const ARROW_OFFSET = 22;
const TOP = 0;
const LEFT = 1;
class TuiHintPositionDirective extends TuiPositionAccessor {
  constructor(options, viewport, directive, accessors) {
    super();
    this.options = options;
    this.viewport = viewport;
    this.directive = directive;
    this.accessors = accessors;
    this.points = TUI_HINT_DIRECTIONS.reduce((acc, direction) => Object.assign(Object.assign({}, acc), {
      [direction]: [0, 0]
    }), {});
    this.direction = this.options.direction;
    this.type = 'hint';
  }
  getPosition({
    width,
    height
  }) {
    var _a, _b;
    const hostRect = (_b = (_a = this.accessor) === null || _a === void 0 ? void 0 : _a.getClientRect()) !== null && _b !== void 0 ? _b : EMPTY_CLIENT_RECT;
    const leftCenter = hostRect.left + hostRect.width / 2;
    const topCenter = hostRect.top + hostRect.height / 2;
    this.points['top-left'][TOP] = hostRect.top - height - OFFSET;
    this.points['top-left'][LEFT] = leftCenter - width + ARROW_OFFSET;
    this.points.top[TOP] = this.points['top-left'][TOP];
    this.points.top[LEFT] = leftCenter - width / 2;
    this.points['top-right'][TOP] = this.points['top-left'][TOP];
    this.points['top-right'][LEFT] = leftCenter - ARROW_OFFSET;
    this.points['bottom-left'][TOP] = hostRect.bottom + OFFSET;
    this.points['bottom-left'][LEFT] = this.points['top-left'][LEFT];
    this.points.bottom[TOP] = this.points['bottom-left'][TOP];
    this.points.bottom[LEFT] = this.points.top[LEFT];
    this.points['bottom-right'][TOP] = this.points['bottom-left'][TOP];
    this.points['bottom-right'][LEFT] = this.points['top-right'][LEFT];
    this.points['left-top'][TOP] = topCenter - height + ARROW_OFFSET;
    this.points['left-top'][LEFT] = hostRect.left - width - OFFSET;
    this.points.left[TOP] = topCenter - height / 2;
    this.points.left[LEFT] = this.points['left-top'][LEFT];
    this.points['left-bottom'][TOP] = topCenter - ARROW_OFFSET;
    this.points['left-bottom'][LEFT] = this.points['left-top'][LEFT];
    this.points['right-top'][TOP] = this.points['left-top'][TOP];
    this.points['right-top'][LEFT] = hostRect.right + OFFSET;
    this.points.right[TOP] = this.points.left[TOP];
    this.points.right[LEFT] = this.points['right-top'][LEFT];
    this.points['right-bottom'][TOP] = this.points['left-bottom'][TOP];
    this.points['right-bottom'][LEFT] = this.points['right-top'][LEFT];
    if (this.checkPosition(this.points[this.direction], width, height)) {
      return this.points[this.direction];
    }
    const direction = TUI_HINT_DIRECTIONS.find(direction => this.checkPosition(this.points[direction], width, height));
    return this.points[direction || this.fallback];
  }
  get accessor() {
    return tuiFallbackRectAccessor('hint')(this.accessors, this.directive);
  }
  get fallback() {
    return this.points.top[TOP] > this.viewport.getClientRect().bottom - this.points.bottom[TOP] ? 'top' : 'bottom';
  }
  checkPosition([top, left], width, height) {
    const viewport = this.viewport.getClientRect();
    return top > OFFSET / 4 && left > OFFSET / 4 && top + height < viewport.bottom - OFFSET / 4 && left + width < viewport.right - OFFSET / 4;
  }
}
TuiHintPositionDirective.ɵfac = function TuiHintPositionDirective_Factory(t) {
  return new (t || TuiHintPositionDirective)(i0.ɵɵdirectiveInject(TUI_HINT_OPTIONS), i0.ɵɵdirectiveInject(TUI_VIEWPORT), i0.ɵɵdirectiveInject(TuiHintDirective), i0.ɵɵdirectiveInject(TuiRectAccessor));
};
TuiHintPositionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintPositionDirective,
  selectors: [["", "tuiHint", "", 5, "ng-container", 5, "ng-template"]],
  inputs: {
    direction: [i0.ɵɵInputFlags.None, "tuiHintDirection", "direction"]
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([tuiPure], TuiHintPositionDirective.prototype, "accessor", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintPositionDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint]:not(ng-container):not(ng-template)'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_HINT_OPTIONS]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TUI_VIEWPORT]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TuiHintDirective]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiRectAccessor]
      }]
    }];
  }, {
    direction: [{
      type: Input,
      args: ['tuiHintDirection']
    }],
    accessor: []
  });
})();
const GAP = 4;
class TuiHintComponent {
  constructor(hovered$, position$, destroy$, animation, pointer, accessor, el, polymorpheus, hover, mode, vvs, viewport) {
    var _a;
    this.animation = animation;
    this.pointer = pointer;
    this.accessor = accessor;
    this.el = el;
    this.polymorpheus = polymorpheus;
    this.hover = hover;
    this.mode = mode;
    this.vvs = vvs;
    this.viewport = viewport;
    this.appearance = this.polymorpheus.$implicit.appearance || ((_a = this.mode) === null || _a === void 0 ? void 0 : _a.mode);
    position$.pipe(map(point => this.vvs.correct(point)), takeUntil(destroy$)).subscribe(([top, left]) => {
      this.update(top, left);
    });
    hovered$.pipe(takeUntil(destroy$)).subscribe(hover => this.hover.toggle(hover));
  }
  get content() {
    return this.polymorpheus.$implicit.content;
  }
  get context() {
    return this.polymorpheus.$implicit.context;
  }
  onClick(target) {
    if (!target.closest('tui-hint') && !this.hover.el.nativeElement.contains(target) || tuiIsObscured(this.hover.el.nativeElement)) {
      this.hover.toggle(false);
    }
  }
  update(top, left) {
    if (!this.hover.el.nativeElement.isConnected) {
      this.hover.toggle(false);
      return;
    }
    const {
      height,
      width
    } = this.el.nativeElement.getBoundingClientRect();
    const {
      style
    } = this.el.nativeElement;
    const rect = this.accessor.getClientRect();
    const viewport = this.viewport.getClientRect();
    if (rect === EMPTY_CLIENT_RECT) {
      return;
    }
    const safeLeft = tuiClamp(left, GAP, viewport.width - width - GAP);
    const [beakTop, beakLeft] = this.vvs.correct([rect.top + rect.height / 2 - top, rect.left + rect.width / 2 - safeLeft]);
    style.top = tuiPx(top);
    style.left = tuiPx(safeLeft);
    style.setProperty('--top', tuiPx(tuiClamp(beakTop, 0.5, height - 1)));
    style.setProperty('--left', tuiPx(tuiClamp(beakLeft, 0.5, width - 1)));
  }
}
TuiHintComponent.ɵfac = function TuiHintComponent_Factory(t) {
  return new (t || TuiHintComponent)(i0.ɵɵdirectiveInject(TuiHoveredService), i0.ɵɵdirectiveInject(TuiPositionService), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TUI_ANIMATION_OPTIONS), i0.ɵɵdirectiveInject(TuiHintPointerDirective, 8), i0.ɵɵdirectiveInject(TuiRectAccessor), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT), i0.ɵɵdirectiveInject(TuiHintHoverDirective), i0.ɵɵdirectiveInject(TuiModeDirective, 8), i0.ɵɵdirectiveInject(TuiVisualViewportService), i0.ɵɵdirectiveInject(TUI_VIEWPORT));
};
TuiHintComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiHintComponent,
  selectors: [["tui-hint"]],
  hostVars: 4,
  hostBindings: function TuiHintComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function TuiHintComponent_click_HostBindingHandler($event) {
        return ctx.onClick($event.target);
      }, false, i0.ɵɵresolveDocument);
    }
    if (rf & 2) {
      i0.ɵɵsyntheticHostProperty("@tuiFadeIn", ctx.animation);
      i0.ɵɵattribute("data-appearance", ctx.appearance);
      i0.ɵɵclassProp("_untouchable", ctx.pointer);
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, TuiPositionService, TuiHoveredService, tuiPositionAccessorFor('hint', TuiHintPositionDirective), tuiRectAccessorFor('hint', TuiHintDirective)])],
  ngContentSelectors: _c0,
  decls: 2,
  vars: 2,
  consts: [[3, "innerHTML", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [3, "innerHTML"]],
  template: function TuiHintComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵtemplate(1, TuiHintComponent_span_1_Template, 1, 1, "span", 0);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("polymorpheusOutlet", ctx.content)("polymorpheusOutletContext", ctx.context);
    }
  },
  dependencies: [i1.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{position:absolute;max-width:18rem;min-height:var(--tui-height-m);padding:.75rem 1rem;background:var(--tui-primary);border-radius:var(--tui-radius-l);color:var(--tui-primary-text);box-sizing:border-box;font:var(--tui-font-text-s);white-space:pre-line;word-wrap:break-word;line-height:1.25rem}[_nghost-%COMP%]:before{content:\"\";position:absolute;top:var(--top);left:var(--left);width:.5rem;height:.5rem;border-radius:.125rem;box-sizing:border-box;background:inherit;transform:translate(-50%,-50%) rotate(45deg)}[data-appearance=error][_nghost-%COMP%]{background:var(--tui-error-fill)}[data-appearance=onDark][_nghost-%COMP%]{background:var(--tui-elevation-02);color:var(--tui-text-01);filter:drop-shadow(0 0 .125rem rgba(0,0,0,.16)) drop-shadow(0 1.5rem 1rem rgba(0,0,0,.03)) drop-shadow(0 .75rem .75rem rgba(0,0,0,.04)) drop-shadow(0 .25rem .375rem rgba(0,0,0,.05))}[_nghost-%COMP%]:not([style*=\"top\"]){visibility:hidden}._untouchable[_nghost-%COMP%]{pointer-events:none}"],
  data: {
    animation: [tuiFadeIn]
  },
  changeDetection: 0
});
__decorate([tuiPure], TuiHintComponent.prototype, "update", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintComponent, [{
    type: Component,
    args: [{
      selector: 'tui-hint',
      template: `
        <ng-content></ng-content>
        <span
            *polymorpheusOutlet="content as text; context: context"
            [innerHTML]="text"
        ></span>
    `,
      styleUrls: ['./hint.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService, TuiPositionService, TuiHoveredService, tuiPositionAccessorFor('hint', TuiHintPositionDirective), tuiRectAccessorFor('hint', TuiHintDirective)],
      animations: [tuiFadeIn],
      host: {
        '[@tuiFadeIn]': 'animation',
        '[class._untouchable]': 'pointer'
      }
    }]
  }], function () {
    return [{
      type: i2$1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiHoveredService]
      }]
    }, {
      type: i2$1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiPositionService]
      }]
    }, {
      type: i2$1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATION_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiHintPointerDirective]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TuiRectAccessor]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }, {
      type: TuiHintHoverDirective,
      decorators: [{
        type: Inject,
        args: [TuiHintHoverDirective]
      }]
    }, {
      type: i5.TuiModeDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiModeDirective]
      }]
    }, {
      type: i2.TuiVisualViewportService,
      decorators: [{
        type: Inject,
        args: [TuiVisualViewportService]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TUI_VIEWPORT]
      }]
    }];
  }, {
    appearance: [{
      type: HostBinding,
      args: ['attr.data-appearance']
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }],
    update: []
  });
})();
class TuiHintDescribeDirective extends TuiDriver {
  constructor(zone, doc, el) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.zone = zone;
    this.doc = doc;
    this.el = el;
    this.id$ = new ReplaySubject(1);
    this.stream$ = this.id$.pipe(tuiIfMap(() => tuiTypedFromEvent(this.doc, 'keydown', {
      capture: true
    }), tuiIsPresent), switchMap(() => this.focused ? of(false) : merge(tuiTypedFromEvent(this.doc, 'keyup'), tuiTypedFromEvent(this.element, 'blur')).pipe(map(() => this.focused))), debounce(visible => visible ? timer(1000) : of(null)), startWith(false), distinctUntilChanged(), skip(1), tuiZoneOptimized(this.zone));
    this.tuiHintDescribe = '';
    this.type = 'hint';
  }
  ngOnChanges() {
    this.id$.next(this.tuiHintDescribe);
  }
  get focused() {
    return tuiIsNativeFocused(this.element);
  }
  get element() {
    return this.doc.getElementById(this.tuiHintDescribe || '') || this.el.nativeElement;
  }
}
TuiHintDescribeDirective.ɵfac = function TuiHintDescribeDirective_Factory(t) {
  return new (t || TuiHintDescribeDirective)(i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(ElementRef));
};
TuiHintDescribeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintDescribeDirective,
  selectors: [["", "tuiHintDescribe", ""]],
  inputs: {
    tuiHintDescribe: "tuiHintDescribe"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintDescribeDirective)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
__decorate([tuiPure], TuiHintDescribeDirective.prototype, "element", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDescribeDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHintDescribe]',
      providers: [tuiAsDriver(TuiHintDescribeDirective)]
    }]
  }], function () {
    return [{
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    tuiHintDescribe: [{
      type: Input
    }],
    element: []
  });
})();
class TuiHintDriverDirective extends AbstractTuiDriverDirective {
  // TODO: Figure out why this is necessary under nx test runner
  constructor(destroy$, drivers, vehicles) {
    super(destroy$, drivers, vehicles);
    this.type = 'hint';
  }
}
TuiHintDriverDirective.ɵfac = function TuiHintDriverDirective_Factory(t) {
  return new (t || TuiHintDriverDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TuiDriver), i0.ɵɵdirectiveInject(TuiVehicle));
};
TuiHintDriverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintDriverDirective,
  selectors: [["", "tuiHint", "", 5, "ng-container", 5, "ng-template"]],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintDriverDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint]:not(ng-container):not(ng-template)',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i2$1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiDriver]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiVehicle]
      }]
    }];
  }, null);
})();
class TuiHintHostDirective extends TuiRectAccessor {
  constructor() {
    super(...arguments);
    this.type = 'hint';
  }
  getClientRect() {
    var _a;
    return ((_a = this.tuiHintHost) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || EMPTY_CLIENT_RECT;
  }
}
TuiHintHostDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiHintHostDirective_BaseFactory;
  return function TuiHintHostDirective_Factory(t) {
    return (ɵTuiHintHostDirective_BaseFactory || (ɵTuiHintHostDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiHintHostDirective)))(t || TuiHintHostDirective);
  };
})();
TuiHintHostDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintHostDirective,
  selectors: [["", "tuiHint", "", "tuiHintHost", ""]],
  inputs: {
    tuiHintHost: "tuiHintHost"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiHintHostDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintHostDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint][tuiHintHost]',
      providers: [tuiAsRectAccessor(TuiHintHostDirective)]
    }]
  }], null, {
    tuiHintHost: [{
      type: Input
    }]
  });
})();
class TuiHintManualDirective extends TuiDriver {
  constructor(hover) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.stream$ = new BehaviorSubject(false);
    this.tuiHintManual = false;
    this.type = 'hint';
    hover.enabled = false;
  }
  ngOnChanges() {
    this.stream$.next(this.tuiHintManual);
  }
}
TuiHintManualDirective.ɵfac = function TuiHintManualDirective_Factory(t) {
  return new (t || TuiHintManualDirective)(i0.ɵɵdirectiveInject(TuiHintHoverDirective));
};
TuiHintManualDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintManualDirective,
  selectors: [["", "tuiHint", "", "tuiHintManual", ""]],
  inputs: {
    tuiHintManual: "tuiHintManual"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiHintManualDirective)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintManualDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHint][tuiHintManual]',
      providers: [tuiAsDriver(TuiHintManualDirective)]
    }]
  }], function () {
    return [{
      type: TuiHintHoverDirective,
      decorators: [{
        type: Inject,
        args: [TuiHintHoverDirective]
      }]
    }];
  }, {
    tuiHintManual: [{
      type: Input
    }]
  });
})();
class TuiHintUnstyledComponent {
  constructor(context) {
    this.context = context;
  }
}
TuiHintUnstyledComponent.ɵfac = function TuiHintUnstyledComponent_Factory(t) {
  return new (t || TuiHintUnstyledComponent)(i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT));
};
TuiHintUnstyledComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiHintUnstyledComponent,
  selectors: [["ng-component"]],
  hostVars: 1,
  hostBindings: function TuiHintUnstyledComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵsyntheticHostProperty("@tuiParentAnimation", undefined);
    }
  },
  decls: 1,
  vars: 2,
  consts: [[4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
  template: function TuiHintUnstyledComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiHintUnstyledComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("polymorpheusOutlet", ctx.context.$implicit.content)("polymorpheusOutletContext", ctx.context);
    }
  },
  dependencies: [i1.PolymorpheusOutletDirective],
  encapsulation: 2,
  data: {
    animation: [TUI_PARENT_ANIMATION]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintUnstyledComponent, [{
    type: Component,
    args: [{
      template: `
        <ng-container
            *polymorpheusOutlet="context.$implicit.content; context: context"
        ></ng-container>
    `,
      host: {
        '[@tuiParentAnimation]': ''
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [TUI_PARENT_ANIMATION]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }];
  }, null);
})();
class TuiHintUnstyledDirective {
  constructor(templateRef, hint) {
    hint.component = new PolymorpheusComponent(TuiHintUnstyledComponent);
    hint.content = templateRef;
  }
}
TuiHintUnstyledDirective.ɵfac = function TuiHintUnstyledDirective_Factory(t) {
  return new (t || TuiHintUnstyledDirective)(i0.ɵɵdirectiveInject(TemplateRef), i0.ɵɵdirectiveInject(TuiHintDirective));
};
TuiHintUnstyledDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHintUnstyledDirective,
  selectors: [["ng-template", "tuiHint", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: POLYMORPHEUS_CONTEXT,
    useValue: {
      $implicit: {}
    }
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintUnstyledDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[tuiHint]',
      providers: [{
        provide: POLYMORPHEUS_CONTEXT,
        useValue: {
          $implicit: {}
        }
      }]
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Inject,
        args: [TemplateRef]
      }]
    }, {
      type: TuiHintDirective,
      decorators: [{
        type: Inject,
        args: [TuiHintDirective]
      }]
    }];
  }, null);
})();
class TuiHintModule {}
TuiHintModule.ɵfac = function TuiHintModule_Factory(t) {
  return new (t || TuiHintModule)();
};
TuiHintModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiHintModule
});
TuiHintModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHintModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule],
      declarations: [TuiHintComponent, TuiHintDirective, TuiHintDriverDirective, TuiHintHostDirective, TuiHintHoverDirective, TuiHintManualDirective, TuiHintPointerDirective, TuiHintDescribeDirective, TuiHintPositionDirective, TuiHintOptionsDirective, TuiHintUnstyledComponent, TuiHintUnstyledDirective],
      exports: [TuiHintComponent, TuiHintDirective, TuiHintDriverDirective, TuiHintHostDirective, TuiHintHoverDirective, TuiHintManualDirective, TuiHintPointerDirective, TuiHintDescribeDirective, TuiHintPositionDirective, TuiHintOptionsDirective, TuiHintUnstyledDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_HINT_COMPONENT, TUI_HINT_DEFAULT_OPTIONS, TUI_HINT_OPTIONS, TuiHintComponent, TuiHintDescribeDirective, TuiHintDirective, TuiHintDriverDirective, TuiHintHostDirective, TuiHintHoverDirective, TuiHintManualDirective, TuiHintModule, TuiHintOptionsDirective, TuiHintPointerDirective, TuiHintPositionDirective, TuiHintUnstyledComponent, TuiHintUnstyledDirective, tuiHintOptionsProvider };
