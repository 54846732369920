import * as i0 from '@angular/core';
import { Directive, Inject, NgModule } from '@angular/core';
import { TuiResizeService, TuiDestroyService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';
class TuiResizeDirective {
  constructor(tuiResize) {
    this.tuiResize = tuiResize;
  }
}
TuiResizeDirective.ɵfac = function TuiResizeDirective_Factory(t) {
  return new (t || TuiResizeDirective)(i0.ɵɵdirectiveInject(TuiResizeService));
};
TuiResizeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiResizeDirective,
  selectors: [["", "tuiResize", ""]],
  outputs: {
    tuiResize: "tuiResize"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, TuiResizeService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiResizeDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiResize]',
      outputs: ['tuiResize'],
      providers: [TuiDestroyService, TuiResizeService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiResizeService]
      }]
    }];
  }, null);
})();

/**
 * @experimental
 */
class TuiResizeModule {}
TuiResizeModule.ɵfac = function TuiResizeModule_Factory(t) {
  return new (t || TuiResizeModule)();
};
TuiResizeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiResizeModule
});
TuiResizeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiResizeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiResizeDirective],
      exports: [TuiResizeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiResizeDirective, TuiResizeModule };
